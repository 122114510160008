import Vue from "vue";
import VRouter from "vue-router";
Vue.use(VRouter);
const Login = () => import("../views/Login");
const Zou = () => import("../views/Zou");
const She = () => import("../views/She");
const Ren = () => import("../views/Ren");
const Lian = () => import("../views/Lian");
const Xin = () => import("../views/Xin");
const Ke = () => import("../views/Ke");
const Chan = () => import("../views/Chan");
const First = () => import("../components/chanyebuju/First");
const Second = () => import("../components/chanyebuju/Second");
const Third = () => import("../components/chanyebuju/Third");
const Fourth = () => import("../components/chanyebuju/Fourth");
const Fifth = () => import("../components/chanyebuju/Fifth");

const Left = () => import("../components/keyan/Left");
const Center = () => import("../components/keyan/Center");
const Right = () => import("../components/keyan/Right");
const Juti = () => import("../components/xinwen/Juti");

const A = () => import("../components/zoujin/A");
const B = () => import("../components/zoujin/B");
const C = () => import("../components/zoujin/C");
const D = () => import("../components/zoujin/D");
const G = () => import("../components/zoujin/G");
const F = () => import("../components/zoujin/F/index");
const E = () => import("../components/zoujin/E/index");
const Meng = () => import("../components/zoujin/culture/Meng");
const Qi = () => import("../components/zoujin/culture/Qi");
const Hui = () => import("../components/zoujin/culture/Hui");
const Si = () => import("../components/zoujin/culture/Si");
const Li = () => import("../components/zoujin/culture/Li");
const Search = () => import("../views/Search/index");

const router = new VRouter({
    // linkActiveClass: "xx",
    routes: [
        {
            path: "/",
            redirect: "/login",
        },
      {
        path: "/search",
        component: Search,
        name: "search",
      },
        {
            path: "/login",
            component: Login,
            name: "login",
        },

        {
            path: "/zou",
            component: Zou,
            children: [
                {
                    path: "/",
                    redirect: "a",
                },
                {
                    path: "a",
                    name: "a",
                    component: A,
                },
                {
                    path: "b",
                    name: "b",
                    component: B,
                },
                {
                    path: "c",
                    name: "c",
                    component: C,
                },
                {
                    path: "e",
                    name: "e",
                    component: E,
                },
                {
                    path: "f",
                    name: "f",
                    component: F,
                },
                {
                    path: "g",
                    name: "g",
                    component: G,
                },
                {
                    path: "d",
                    name: "d",
                    component: D,
                    redirect: "d/meng",
                    children: [
                        {
                            path: "",
                            redirect: "meng",
                        },
                        {
                            path: "meng",
                            name: "meng",
                            component: Meng,
                        },

                        {
                            path: "qi",
                            name: "qi",
                            component: Qi,
                        },
                        {
                            path: "hui",
                            name: "hui",
                            component: Hui,
                        },
                        {
                            path: "si",
                            name: "si",
                            component: Si,
                        },
                        {
                            path: "li",
                            name: "li",
                            component: Li,
                        },
                    ],
                },
            ],
        },
        {
            path: "/she",
            name: "she",
            component: She,
        },
        {
            path: "/ren",
            name: "ren",
            component: Ren,
        },
        {
            path: "/lian",
            name: "lian",
            component: Lian,
        },
        {
            path: "/chan",
            name: "chan",
            component: Chan,
            redirect: "chan/first",
            children: [
                {
                    path: "",
                    redirect: "first",
                },
                {
                    path: "first",
                    name: "first",
                    component: First,
                },
                {
                    path: "second",
                    name: "second",
                    component: Second,
                },
                {
                    path: "third",
                    name: "third",
                    component: Third,
                },
                {
                    path: "fourth",
                    name: "fourth",
                    component: Fourth,
                },
                {
                    path: "fifth",
                    name: "fifth",
                    component: Fifth,
                },
            ],
        },
        {
            path: "/ke",
            name: "ke",
            component: Ke,
            redirect: "ke/left",
            children: [
                {
                    path: "",
                    redirect: "left",
                },
                {
                    path: "left",
                    name: "left",
                    component: Left,
                },
                {
                    path: "center",
                    name: "center",
                    component: Center,
                },
                {
                    path: "right",
                    name: "right",
                    component: Right,
                },
            ],
        },
        {
            path: "/xin",
            name: "xin",
            component: Xin,
        },

        {
            path: "/juti",
            name: "juti",
            component: Juti,
        },
    ],
    // linkActiveClass: "xx",
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }

});
export default router;
