<template>
    <div :class="{ sousuo: true }" v-show="searchActive === 'show'">
        <input
                ref="input"
                class="search"
                v-model="keyword"
                type="text"
                name=""
                placeholder="搜索"
                id=""
                @keyup.enter="goSearch"
        />
        <span class="icon" @click="goSearch">
            <img src="./images/组 21.png" alt="" />
        </span>
    </div>
</template>

<script>
  export default {
    name: "Sousuo",
    props: ["searchActive"],
    data() {
      return { keyword: "" };
    },
    methods: {
      goSearch() {
        this.$emit("update:searchActive", "");
        this.$router.push({
          name: "search",
          query: { keyword: this.keyword },
        });
      },
    },
    watch: {
      searchActive() {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      },
    },
  };
</script>

<style scoped lang="less">
    .sousuo {
        z-index: 10000;
        position: absolute;
        bottom: -2.0417vw;
        right: 2.6042vw;
        .search {
            outline: 0;
            padding: 0.8417vw;
            width: 15.625vw;
            height: 2.0833vw;
            border-radius: 0.4208vw;
            box-sizing: border-box;
        }
        .search:focus {
            border: 0;
            outline: 1px solid rgb(200, 0, 0);
            box-shadow: 0 0 1px 1px rgba(200, 0, 0, 0.5);
        }
        .icon {
            position: absolute;
            right: 1.1625vw;
            top: 0.4604vw;
            img {
                width: 1.1vw;
            }
        }
    }
</style>
