import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import router from './router/router'
Vue.prototype.$http = axios
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  created() {
    Vue.prototype.$bus = this;
  }
}).$mount('#app')
