<template>
    <div id="app">
        <dao v-if="$route.name != 'login'"></dao>
        <router-view></router-view>
    </div>
</template>

<script>
    import {getSeoInfo} from  "./api/seoyouhua"
import Dao from "./components/common/Dao.vue";
export default {
    name: "App",
  data() {
    return {
      data: [],
    };
  },
  components: {
    Dao,
  },
  // metaInfo() {
  //     return {
  //   title: this.data.title, // set a title
  //   meta: [{             // set meta
  //     name: 'keyWords',
  //     content: this.data.keyword
  //   },
  //     {
  //       name: 'description',
  //       content: this.data.description
  //     }]
  //
  //     }
  // },


  methods: {
      _isMobile (){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },

    },
  mounted(){

    getSeoInfo().then((res)=>{
      // console.log(res.data);
      return res;
    })
        .then((res)=>{
          // 获取响应数据
          let data = res.data;
          this.data = data;
          console.log(data,"res11111166666666");
        });




    if (this._isMobile()) {
  // alert("手机端");
      window.location.href = 'http://m.wj.jianong.com';
} else {
      // alert("pc端");
      // var url = 'http://m.wj.jianong.com/';
  //     window.open('http://dwj.danjiguanjia.com/');
  //     window.open('http://m.wj.jianong.com/',"_blank");
      // window.localtion.href = url;
      // window.location.href = 'http://dwj.danjiguanjia.com';
}
}
}
</script>
<style>
* {
    margin: 0;
    padding: 0;
    list-style: none;
}
html,
body,
div,
span,
button,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    color: #000;
    text-decoration: none;
}
a:hover {
    color: #000;
    text-decoration: none;
}
</style>
