<template>
    <header>
        <Sousuo :searchActive.sync="searchActive"></Sousuo>

        <div class="top">
            <li class="left"></li>
            <li class="right"></li>
        </div>
        <div class="header">
            <div class="inner">
                <div class="logo">
                    <a href="#/login">
                        <img class="ffff" src="../../assets/LOGO.png" alt="" />
                    </a>
                </div>
                <div class="tab">
                    <li>
                        <a class="p-a" href="#/login">首页</a>
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/zou/a"
                                :class="{
                             haha: haha == 'a'|| haha == 'b'|| haha == 'g'|| haha == 'c' || haha == 'e' || haha == 'f'|| haha == 'meng'

                             }"
                                @click="haha = 'a'"
                        >
                            走进大伟嘉
                        </a>
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >走进大伟嘉</a
                                >
                                <span>丨</span>
                                <!--:to="{name:'a',query:{id:'a'}}"-->
                                <a
                                        class="s-a"
                                        href="#/zou/a"
                                        :class="{ haha: haha == 'a' }"
                                        @click="haha = 'a'"
                                >大伟嘉简介</a
                                >
                                <a
                                        class="s-a"
                                        href="#/zou/b"
                                        :class="{ haha: haha == 'b' }"
                                        @click="haha = 'b'"
                                >董事长介绍</a
                                >
                                <a
                                        class="s-a"
                                        href="#/zou/g"
                                        :class="{ haha: haha == 'g' }"
                                        @click="haha = 'g'"
                                >领导关怀</a
                                >
                                <a
                                        class="s-a"
                                        href="#/zou/c"
                                        :class="{ haha: haha == 'c' }"
                                        @click="haha = 'c'"
                                >企业荣誉</a
                                >
                                <!-- <a class="s-a" href="#/zou/a" v-for="item in data" :key="item.id">{{item.title}}</a> -->
                                <a
                                        class="s-a"
                                        href="#/zou/e"
                                        :class="{ haha: haha == 'e' }"
                                        @click="haha = 'e'"
                                >产品中心</a
                                >
                                <a
                                        class="s-a"
                                        href="#/zou/f"
                                        :class="{ haha: haha == 'f' }"
                                        @click="haha = 'f'"
                                >发展历程</a
                                >
                                <a
                                        class="s-a"
                                        href="#/zou/d/meng"
                                        :class="{ haha: haha == 'meng' }"
                                        @click="haha = 'meng'"
                                >企业文化</a
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/xin"
                                :class="{ haha: haha == 'xin' }"
                                @click="haha = 'xin'"
                        >新闻资讯</a
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >新闻资讯</a
                                >
                                <span>丨</span>
                                <router-link
                                        @click.native="updateId(i)"
                                        :to="{ name: 'xin' }"
                                        class="s-a"
                                        v-for="(item1, i) in dat"
                                        :key="item1.id"
                                >{{ item1.name }}</router-link
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/chan/first"
                                :class="{
                            haha: haha == 'first'|| haha == 'second' || haha == 'third' || haha == 'fourth'|| haha == 'fifth'

                            }"
                                @click="haha = 'first'"
                        >产业布局</a
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >产业布局</a
                                >
                                <span>丨</span>
                                <a
                                        class="s-a"
                                        href="#/chan/first"
                                        :class="{ haha: haha == 'first' }"
                                        @click="haha = 'first'"
                                >生物育种</a
                                >
                                <a
                                        class="s-a"
                                        href="#/chan/second"
                                        :class="{ haha: haha == 'second' }"
                                        @click="haha = 'second'"
                                >生物饲料</a
                                >
                                <a
                                        class="s-a"
                                        href="#/chan/third"
                                        :class="{ haha: haha == 'third' }"
                                        @click="haha = 'third'"
                                >生物兽药</a
                                >
                                <a
                                        class="s-a"
                                        href="#/chan/fourth"
                                        :class="{ haha: haha == 'fourth' }"
                                        @click="haha = 'fourth'"
                                >健康养殖</a
                                >
                                <a
                                        class="s-a"
                                        href="#/chan/fifth"
                                        :class="{ haha: haha == 'fifth' }"
                                        @click="haha = 'fifth'"
                                >产业服务</a
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/ke/left"
                                :class="{ haha: haha == 'left'||haha == 'center'||haha == 'right' }"
                                @click="haha = 'left'"
                        >科研创新</a
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >科研创新</a
                                >
                                <span>丨</span>
                                <a
                                        class="s-a"
                                        href="#/ke/left"
                                        :class="{ haha: haha == 'left' }"
                                        @click="haha = 'left'"
                                >科研体系</a
                                >
                                <a
                                        class="s-a"
                                        href="#/ke/center"
                                        :class="{ haha: haha == 'center' }"
                                        @click="haha = 'center'"
                                >科研实力</a
                                >
                                <a
                                        class="s-a"
                                        href="#/ke/right"
                                        :class="{ haha: haha == 'right' }"
                                        @click="haha = 'right'"
                                >科研成果</a
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/ren"
                                :class="{ haha: haha == 'ren' }"
                                @click="haha = 'ren'"
                        >人才中心</a
                        >
                    </li>
                    <li>
                        <a
                                class="p-a"
                                href="#/she"
                                :class="{ haha: haha == 'she' }"
                                @click="haha = 'she'"
                        >社会责任</a
                        >
                    </li>

                    <li>
                        <a
                                class="p-a"
                                href="#/lian"
                                :class="{ haha: haha == 'lian' }"
                                @click="haha = 'lian'"
                        >联系我们</a
                        >
                    </li>
                </div>
                <div class="search" @click="searchActive = 'show'">
                    <img src="../../assets/搜索.png" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
  import { getAboutList, getNewsCategoty } from "../../api/index";
  import Sousuo from "../common/Sousuo";

  export default {
    components: { Sousuo },
    name: "Top",
    data() {
      return {
        data: [],
        dat: [],
        haha: "",
        gg: "",
        searchActive: "",
      };
    },
    methods: {
      xin() {
        this.$nextTick(() => {
          this.active = "xin";
        });
        this.$router.push({ name: "xin" });
      },
      updateId(i) {
        // console.log(i,"???")
        this.$bus.$emit("updateId", i);
      },
    },
    destroyed() {
      console.log("我被销毁了好难过");
    },
    mounted() {
      this.haha = this.$route.query.id;
      getAboutList().then((res) => {
        // console.log(res.data);
        let data = res.data;
        this.data = data;
      }),
          getNewsCategoty().then((resa) => {
            // console.log(resa.data);
            let dat = resa.data;
            this.dat = dat;
          });
    },
    // watch: {
    //     $route: {
    //         immediate: true,
    //         deep: true,
    //         handler(newV) {
    //             console.log("哈喽大家好各位晚安");
    //             this.haha = newV.query.id;
    //             console.log(newV);
    //         },
    //     },
    // },
  };
</script>

<style lang="less">
    .haha {
        color: red !important;
    }

    a:hover {
        color: #d3001c;
    }
    header {
        position: relative;
        display: block;
        .top {
            height: 2vh;
            display: flex;
            li {
                float: right;
            }
            .left {
                width: 30%;
                background-color: #d7000f;
            }
            .right {
                flex: 1;
                background-color: #003fa8;
            }
        }
        .header {
            background-color: #f6f5fa;
            position: relative;
            z-index: 10;
            height: 9vh;
            .inner {
                width: 100%;
                margin: 0 auto;
                display: flex;
            }
            .logo {
                width: 30%;
                display: flex;
                align-items: center;
                .ffff {
                    height: 5.5vh !important;
                    margin-left: 100% !important;
                    margin-bottom: 0 !important;
                    margin-top: 0 !important;
                }
            }
            .tab {
                margin: 0 2%;
                flex: 1;
                display: flex;
                justify-content: space-between;
                .p-a {
                    font-family: PingFang SC, PingFang SC-Regular;
                    display: flex;
                    height: 9vh;
                    padding: 0 0;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 0.2809vh solid transparent;
                    color: #000000;
                    font-size: 1.0417vw;
                }
                li {
                    &.haha,
                    &:hover {
                        .p-a {
                            color: red;
                            /*border-bottom: 3px solid red;*/
                        }
                        .sub {
                            display: flex;
                            opacity: 1;
                        }
                    }
                }
                .sub {
                    position: absolute;
                    display: none;
                    opacity: 0;
                    left: 0;
                    right: 0;
                    top: 100%;
                    background-color: rgba(255, 255, 255, 0.8);
                    .s-i {
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        padding-left: 30%;
                        align-items: center;
                    }
                    .s {
                        display: inline-flex;
                        height: 8vh;
                        align-items: center;
                        justify-content: center;
                        padding: 0 1.0417vw;
                        font-size: 0.8333vw;
                        color: #666;
                    }
                    .s-a {
                        display: inline-flex;
                        height: 8vh;
                        align-items: center;
                        justify-content: center;
                        padding: 0 1.0417vw;
                        font-size: 0.8333vw;
                        color: #666;
                        &:hover {
                            background-color: #d7000f;
                            color: white;
                        }
                    }
                }
            }
            .search {
                width: 7% !important;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .nnnn {
                width: 30% !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }
    }
</style>
